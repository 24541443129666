<template>
  <div>
    <div class="buttons justify-content-start mt-2">
      <download-excel
        class="button primary sm"
        :data="quizzes"
        :fields="fieldsExport"
        name="Questionários"
      >
        <b-icon
          class="mr-2"
          scale="1"
          icon="file-earmark-excel-fill"
        />Exportar
        para excel
      </download-excel>
    </div>
    <b-input-group
      size="sm"
      class="mb-4"
    >
      <template #prepend>
        <b-input-group-text>
          <b-icon
            icon="search"
            scale="0.8"
          />
        </b-input-group-text>
      </template>
      <b-form-input
        id="filter-input"
        v-model="filterQuizzes"
        type="search"
        placeholder="Pesquisar... (Empresa e Submetido por)"
      />

      <b-input-group-append>
        <b-button
          :disabled="!filterQuizzes"
          @click="filterQuizzes = ''"
        >
          Limpar
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <b-table
      v-if="quizzes"
      id="quizzes"
      :items="quizzes"
      :per-page="perPageQuizzes"
      :current-page="currentPageQuizzes"
      :fields="fieldsQuizzes"
      :filter="filterQuizzes"
      :filter-included-fields="filterOnQuizzes"
      borderless
      hover
      small
    >
      <template #cell(count)="row">
        {{ row.item.count }}/{{ row.item.total }}
      </template>
      <template #cell(facilitatorReport)="{value}">
        <b-icon
          v-if="value"
          icon="check"
        />
      </template>
      <template #cell(actions)="row">
        <div class="buttons justify-content-start my-0 buttons-group">
          <div
            v-if="row.item.status === 'submitted'"
            class="button primary xs"
            @click="toggleDetails(row)"
          >
            <b-icon
              scale="1.2"
              :icon="row.detailsShowing ? 'eye-slash' : 'eye'"
            />
          </div>
          <div
            v-if="row.item.status === 'submitted'"
            class="button primary xs"
            @click="download(row.item.id)"
          >
            <b-icon
              scale="1.2"
              icon="download"
            />
          </div>

          <b-dropdown
            class="last"
            right
            no-caret
          >
            <template #button-content>
              <div
                class="button primary xs m-0"
                :style="
                  row.item.status !== 'submitted'
                    ? 'border-radius: 0.2857rem'
                    : ''
                "
              >
                <b-icon
                  scale="1.2"
                  icon="three-dots"
                />
              </div>
            </template>
            <b-dropdown-item
              v-if="row.item.report"
            >
              <div
                class="button outlined no-border dark sm"
                @click="downloadFacilitatorReport(row.item.filename, row.item.report)"
              >
                <b-icon
                  class="mr-2"
                  scale="1.2"
                  icon="download"
                /> Relatório de Facilitador
              </div>
            </b-dropdown-item>
            <b-dropdown-item>
              <div
                class="button outlined no-border dark sm"
                @click="goToQuiz(row.item.id)"
              >
                <b-icon
                  class="mr-2"
                  scale="1.2"
                  icon="ui-checks"
                />Consultar
                questionário
              </div>
            </b-dropdown-item>
            <b-dropdown-item>
              <download-excel
                :fetch="() => getResponses(row.item.id)"
                :fields="fieldsExportQuiz"
                class="button outlined no-border dark sm"
                name="Respostas"
              >
                <b-icon
                  class="mr-2"
                  scale="1.2"
                  icon="file-earmark-excel-fill"
                />Exportar respostas para excel
              </download-excel>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template #row-details="row">
        <b-overlay
          :show="toggling"
          opacity="0.5"
        >
          <div
            v-if="report && row.item.status === 'submitted'"
            class="dahsboard-reports"
          >
            <div class="report dashboard-reports">
              <div class="report-header">
                <div
                  style=" align-items: center; display: flex; flex-direction: row; gap: 8px;"
                >
                  <b>Avaliação global (ponderada):
                    <span
                      class="text-primary ml-2 mr-2"
                    >{{
                      parseFloat(report.global[0].classification).toFixed(2)
                    }}
                      - Nível
                      {{ Math.floor(report.global[0].classification) }}</span></b>
                  <!--  <div
                                  v-if="report.quiz[0] && report.quiz[0].pdf"
                                  class="button primary mr-2 sm"
                                  @click="download(report.quiz[0].pdf)"
                                >
                                  <b-icon
                                    scale="1"
                                    icon="download"
                                    class="mr-3"
                                  />Download
                                </div> -->
                </div>
              </div>
              <div>
                <div class="mb-2">
                  Data: {{ report.global[0].updated_at }}
                </div>
                <div
                  v-if="report.global[0] && report.dimensions"
                  class="report-dimensions-classification"
                >
                  <div class="title">
                    Nas 6 dimensões analisadas, os níves de maturidade i4.0 são
                    os seguintes:
                  </div>
                  <div class="row-dimensions-classification">
                    <div class="dimensions">
                      <div class="row-dimensions dimensions-header">
                        <div class="dimension">
                          Dimensão
                        </div>
                        <div class="classification">
                          Nível Atual
                        </div>
                        <div class="classification">
                          Nível Futuro
                        </div>
                      </div>
                      <div
                        v-for="(dim, j) in Object.keys(report.dimensions)"
                        :key="j"
                        class="row-dimensions"
                      >
                        <div class="dimension">
                          {{ report.dimensions[dim].name }}
                        </div>
                        <div class="classification">
                          {{ report.dimensions[j].classification }}
                        </div>
                        <div class="classification">
                          {{ report.future[j].value }}
                        </div>
                      </div>
                      <div
                        class="row-dimensions"
                      >
                        <div class="dimension">
                          Avaliação
                        </div>
                        <div
                          class="classification bg-primary text-light"
                          style="border-top-left-radius: 5px; border-bottom-left-radius: 5px"
                        >
                          {{ report && report.global ? Math.floor(report.global[0].classification) : null }}
                        </div>
                        <div
                          class="classification bg-primary text-light"
                          style="border-top-right-radius: 5px; border-bottom-right-radius: 5px"
                        >
                          {{ Math.floor(report.future_classification) }}
                        </div>
                      </div>
                    </div>

                    <div class="classifications">
                      <img
                        :src="
                          require(`@/assets/N${Math.floor(
                            report.global[0].classification
                          )}.svg`)
                        "
                      >
                    </div>
                  </div>
                </div>
                <div
                  v-if="report.quiz[0].svg && report.dimensions"
                  class="report-themes-classification"
                >
                  <div class="title">
                    Na avaliação dos temas, de cada dimensão, obteve as
                    seguintes classificações:
                  </div>
                  <div class="row-themes-classification">
                    <div
                      class="svg"
                      v-html="report.quiz[0].svg"
                    />

                    <div class="table">
                      <div class="row-dimensions">
                        <div class="dimension">
                          Dimensão
                        </div>
                        <div class="themes">
                          <div class="row-themes">
                            <div class="theme">
                              Tema
                            </div>
                            <div class="classification">
                              Nível
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-for="(dim, j) in Object.keys(report.dimensions)"
                        :key="`dimension-${j}`"
                        class="row-dimensions"
                      >
                        <div class="dimension">
                          {{ report.dimensions[dim].name }}
                        </div>
                        <div class="themes">
                          <div
                            v-for="(theme, i) in Object.keys(
                              report.themes.filter(
                                (e) =>
                                  e.dimension_id == report.dimensions[dim].id
                              )
                            )"
                            :key="`theme-${i}`"
                            class="row-themes"
                          >
                            <div class="theme">
                              {{
                                report.themes.filter(
                                  (e) =>
                                    e.dimension_id == report.dimensions[dim].id
                                )[theme].name
                              }}
                            </div>
                            <div class="classification">
                              {{
                                report.themes[
                                  report.themes.indexOf(
                                    report.themes.filter(
                                      (e) =>
                                        e.dimension_id ==
                                        report.dimensions[dim].id
                                    )[theme]
                                  )
                                ].classification
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="dahsboard-reports"
          >
            <div class="report dashboard-reports">
              <div class="report-header">
                <div
                  style=" align-items: center; display: flex; flex-direction: row; gap: 8px;"
                >
                  <b>Sem dados</b>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPageQuizzes"
      :total-rows="rowsQuizzes"
      :per-page="perPageQuizzes"
      aria-controls="quizzes"
    />
  </div>
</template>

<script>
import { get } from '../../../services/api';

export default {
  name: 'QuizzesTab',
  props: {
    setLoading: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      report: null,
      toggling: false,
      regions: {
        'R-1': 'Alto Minho',
        'R-2': 'Cávado',
        'R-3': 'Ave',
        'R-4': 'Área Metropolitana do Porto',
        'R-5': 'Alto Tâmega',
        'R-6': 'Tâmega e Sousa',
        'R-7': 'Douro',
        'R-8': 'Terras de Trás-os-Montes',
        'R-9': 'Oeste',
        'R-10': 'Região de Aveiro',
        'R-11': 'Região de Coimbra',
        'R-12': 'Região de Leiria',
        'R-13': 'Viseu Dão Lafões',
        'R-14': 'Beira Baixa',
        'R-15': 'Médio Tejo',
        'R-16': 'Beiras e Serra da Estrela',
        'R-17': 'Área Metroplotinada de Lisboa',
        'R-18': 'Alentejo Litoral',
        'R-19': 'Baixo Alentejo',
        'R-20': 'Lezíria do Tejo',
        'R-21': 'Alto Alentejo',
        'R-22': 'Alentejo Central',
        'R-23': 'Algarve',
        'R-24': 'Região Autónoma dos Açores',
        'R-25': 'Região Autónoma da Madeira',
      },
      choices: [
        {
          text:
            'Agricultura, produção animal, caça e actividades dos serviços relacionados',
          value: 1,
        },
        { text: 'Silvicultura e exploração florestal', value: 2 },
        { text: 'Pesca e aquicultura', value: 3 },
        { text: 'Extracção de hulha e lenhite', value: 5 },
        { text: 'Extracção de petróleo bruto e gás natural', value: 6 },
        { text: 'Extracção e preparação de minérios metálicos', value: 7 },
        { text: 'Outras indústrias extractivas', value: 8 },
        {
          text:
            'Actividades dos serviços relacionados com as indústrias extractivas',
          value: 9,
        },
        { text: 'Indústrias alimentares', value: 10 },
        { text: 'Indústria das bebidas', value: 11 },
        { text: 'Indústria do tabaco', value: 12 },
        { text: 'Fabricação de têxteis', value: 13 },
        { text: 'Indústria do vestuário', value: 14 },
        { text: 'Indústria do couro e dos produtos do couro', value: 15 },
        {
          text:
            'Indústrias da madeira e da cortiça e suas obras, excepto mobiliário; fabricação de obras de cestaria e de espartaria',
          value: 16,
        },
        {
          text: 'Fabricação de pasta, de papel, cartão e seus artigos',
          value: 17,
        },
        { text: 'Impressão e reprodução de suportes gravados', value: 18 },
        {
          text:
            'Fabricação de coque, de produtos petrolíferos refinados e de aglomerados de combustíveis',
          value: 19,
        },
        {
          text:
            'Fabricação de produtos químicos e de fibras sintéticas ou artificiais, excepto produtos farmacêuticos',
          value: 20,
        },
        {
          text:
            'Fabricação de produtos farmacêuticos de base e de preparações farmacêuticas',
          value: 21,
        },
        {
          text: 'Fabricação de artigos de borracha e de matérias plásticas',
          value: 22,
        },
        {
          text: 'Fabricação de outros produtos minerais não metálicos',
          value: 23,
        },
        { text: 'Indústrias metalúrgicas de base', value: 24 },
        {
          text:
            'Fabricação de produtos metálicos, excepto máquinas e equipamentos',
          value: 25,
        },
        {
          text:
            'Fabricação de equipamentos informáticos, equipamento para comunicações e produtos electrónicos e ópticos',
          value: 26,
        },
        { text: 'Fabricação de equipamento eléctrico', value: 27 },
        { text: 'Fabricação de máquinas e de equipamentos, n.e.', value: 28 },
        {
          text:
            'Fabricação de veículos automóveis, reboques, semi-reboques e componentes para veículos automóveis',
          value: 29,
        },
        { text: 'Fabricação de outro equipamento de transporte', value: 30 },
        { text: 'Fabricação de mobiliário e de colchões', value: 31 },
        { text: 'Outras indústrias transformadoras', value: 32 },
        {
          text: 'Reparação, manutenção e instalação de máquinas e equipamentos',
          value: 33,
        },
        {
          text: 'Electricidade, gás, vapor, água quente e fria e ar frio',
          value: 35,
        },
        { text: 'Captação, tratamento e distribuição de água', value: 36 },
        {
          text: 'Recolha, drenagem e tratamento de águas residuais',
          value: 37,
        },
        {
          text:
            'Recolha, tratamento e eliminação de resíduos; valorização de materiais',
          value: 38,
        },
        { text: 'Descontaminação e actividades similares', value: 39 },
        {
          text:
            'Promoção imobiliária (desenvolvimento de projectos de edifícios); construção de edifícios',
          value: 41,
        },
        { text: 'Engenharia civil', value: 42 },
        { text: 'Actividades especializadas de construção', value: 43 },
        {
          text:
            'Comércio, manutenção e reparação, de veículos automóveis e motociclos',
          value: 45,
        },
        {
          text:
            'Comércio por grosso (inclui agentes), excepto de veículos automóveis e motociclos',
          value: 46,
        },
        {
          text:
            'Comércio a retalho, excepto de veículos automóveis e motociclos',
          value: 47,
        },
        {
          text:
            'Transportes terrestres e transportes por oleodutos ou gasodutos',
          value: 49,
        },
        { text: 'Transportes por água', value: 50 },
        { text: 'Transportes aéreos', value: 51 },
        {
          text:
            'Armazenagem e actividades auxiliares dos transportes(inclui manuseamento)',
          value: 52,
        },
        { text: 'Actividades postais e de courier', value: 53 },
        { text: 'Alojamento', value: 55 },
        { text: 'Restauração e similares', value: 56 },
        { text: 'Actividades de edição', value: 58 },
        {
          text:
            'Actividades cinematográficas, de vídeo, de produção de programas de televisão, de gravação de som e de edição de música',
          value: 59,
        },
        { text: 'Actividades de rádio e de televisão', value: 60 },
        { text: 'Telecomunicações', value: 61 },
        {
          text:
            'Consultoria e programação informática e actividades relacionadas',
          value: 62,
        },
        { text: 'Actividades dos serviços de informação', value: 63 },
        {
          text:
            'Actividades de serviços financeiros, excepto seguros e fundos de pensões',
          value: 64,
        },
        {
          text:
            'Seguros, resseguros e fundos de pensões, excepto segurança social obrigatória',
          value: 65,
        },
        {
          text: 'Actividades auxiliares de serviços financeiros e dos seguros',
          value: 66,
        },
        { text: 'Actividades imobiliárias', value: 68 },
        { text: 'Actividades jurídicas e de contabilidade', value: 69 },
        {
          text: 'Actividades das sedes sociais e de consultoria para a gestão',
          value: 70,
        },
        {
          text:
            'Actividades de arquitectura, de engenharia e técnicas afins; actividades de ensaios e de análises técnicas',
          value: 71,
        },
        {
          text: 'Actividades de Investigação científica e de desenvolvimento',
          value: 72,
        },
        {
          text: 'Publicidade, estudos de mercado e sondagens de opinião',
          value: 73,
        },
        {
          text:
            'Outras actividades de consultoria, científicas, técnicas e similares',
          value: 74,
        },
        { text: 'Actividades veterinárias', value: 75 },
        { text: 'Actividades de aluguer', value: 77 },
        { text: 'Actividades de emprego', value: 78 },
        {
          text:
            'Agências de viagem, operadores turísticos, outros serviços de reservas e actividades relacionadas',
          value: 79,
        },
        { text: 'Actividades de investigação e segurança', value: 80 },
        {
          text:
            'Actividades relacionadas com edifícios, plantação e manutenção de jardins',
          value: 81,
        },
        {
          text:
            'Actividades de serviços administrativos e de apoio prestados às empresas',
          value: 82,
        },
        {
          text: 'Administração Pública e Defesa; Segurança Social Obrigatória',
          value: 84,
        },
        { text: 'Educação', value: 85 },
        { text: 'Actividades de saúde humana', value: 86 },
        { text: 'Actividades de apoio social com alojamento', value: 87 },
        { text: 'Actividades de apoio social sem alojamento', value: 88 },
        {
          text:
            'Actividades de teatro, de música, de dança e outras actividades artísticas e literárias',
          value: 90,
        },
        {
          text:
            'Actividades das bibliotecas, arquivos, museus e outras actividades culturais',
          value: 91,
        },
        { text: 'Lotarias e outros jogos de aposta', value: 92 },
        {
          text: 'Actividades desportivas, de diversão e recreativas',
          value: 93,
        },
        { text: 'Actividades das organizações associativas', value: 94 },
        {
          text:
            'Reparação de computadores e de bens de uso pessoal e doméstico',
          value: 95,
        },
        { text: 'Outras actividades de serviços pessoais', value: 96 },
        {
          text: 'Actividades das famílias empregadoras de pessoal doméstico',
          value: 97,
        },
        {
          text:
            'Actividades de produção de bens e serviços pelas famílias para uso próprio',
          value: 98,
        },
        {
          text:
            'Actividades dos organismos internacionais e outras instituições extraterritoriais',
          value: 99,
        },
      ],
      perPageQuizzes: 10,
      filterQuizzes: null,
      filterOnQuizzes: [
        'company',
        'status',
        'submitted',
        'created_at',
        'updated_at',
        'finished_at',
        'count',
      ],
      currentPageQuizzes: 1,
      status: {
        open: { icon: 'check-circle', color: 'green', label: 'Ativo' },
        submitted: { icon: 'x-circle', color: 'blue', label: 'Fechado' },
        rejected: { icon: 'bookmark-x', color: 'red', label: 'Rejeitado' },
        approved: { icon: 'bookmark-check', color: 'green', label: 'Aprovado' },
        default: { icon: 'list-check', color: 'green', label: '' },
      },
      fieldsExportQuiz: {
        Dimensão: 'dimension',
        Questão: 'question',
        Opção: 'option',
        'Label do valor': 'label',
        Valor: 'value',
        'Código da dimensão': 'dimension_code',
        'Código da questão': 'question_code',
        'Tipo de questão': 'question_type',
        'Código do valor': 'value_code',
      },
      fieldsExport: {
        Estado: {
          field: 'status',
          callback: (value) => this.status[value].label,
        },
        Email: {
          field: 'submitted.email',
        },
        Empresa: 'company.name',
        Região: {
          field: 'company.region',
          callback: (value) => this.regions[`R-${value}`],
        },
        Setor: {
          field: 'company.economic_sector',
          callback: (value) => {
            const response = this.choices.find((el) => el.value == value);
            return response ? response.text : '';
          },
        },
        'Submetido por': 'submitted.name',
        Facilitador: 'name',
        'Email do facilitador': 'email',
        'Iniciado em (data)': {
          field: 'created_at',
          callback: (value) => {
            const date = new Date(value);
            return [
              date.getDate().toString().length < 2
                ? `0${date.getDate()}`
                : date.getDate(),
              (date.getMonth() + 1).toString().length < 2
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1,
              date.getFullYear(),
            ].join('/');
          },
        },
        'Iniciado em (hora)': {
          field: 'created_at',
          callback: (value) => {
            const date = new Date(value);
            return [
              date.getHours().toString().length < 2
                ? `0${date.getHours()}`
                : date.getHours(),
              date.getMinutes().toString().length < 2
                ? `0${date.getMinutes()}`
                : date.getMinutes(),
            ].join(':');
          },
        },
        'Última alteração (data)': {
          field: 'updated_at',
          callback: (value) => {
            const date = new Date(value);
            return [
              date.getDate().toString().length < 2
                ? `0${date.getDate()}`
                : date.getDate(),
              (date.getMonth() + 1).toString().length < 2
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1,
              date.getFullYear(),
            ].join('/');
          },
        },
        'Última alteração (hora)': {
          field: 'updated_at',
          callback: (value) => {
            const date = new Date(value);
            return [
              date.getHours().toString().length < 2
                ? `0${date.getHours()}`
                : date.getHours(),
              date.getMinutes().toString().length < 2
                ? `0${date.getMinutes()}`
                : date.getMinutes(),
            ].join(':');
          },
        },
        'Finalizado em (data)': {
          field: 'finished_at',
          callback: (value) => {
            const date = new Date(value);
            if (date != 'Invalid Date') {
              return [
                date.getDate().toString().length < 2
                  ? `0${date.getDate()}`
                  : date.getDate(),
                (date.getMonth() + 1).toString().length < 2
                  ? `0${date.getMonth() + 1}`
                  : date.getMonth() + 1,
                date.getFullYear(),
              ].join('/');
            }
            return '';
          },
        },
        'Finalizado em (hora)': {
          field: 'finished_at',
          callback: (value) => {
            const date = new Date(value);
            if (date != 'Invalid Date') {
              return [
                date.getHours().toString().length < 2
                  ? `0${date.getHours()}`
                  : date.getHours(),
                date.getMinutes().toString().length < 2
                  ? `0${date.getMinutes()}`
                  : date.getMinutes(),
              ].join(':');
            }
            return '';
          },
        },
        'Nº de respostas dadas': {
          callback: (value) => value.count,
        },
      },
      fieldsQuizzes: [
        {
          key: 'status',
          sortable: true,
          label: 'Estado',
          formatter: (value) => this.status[value].label,
        },
        {
          key: 'company',
          sortable: true,
          label: 'Empresa',
          filterByFormatted: true,
          formatter: (value) => value?.name,
        },
        {
          key: 'submitted',
          sortable: true,
          label: 'Submetido por',
          filterByFormatted: true,
          formatter: (value) => value?.name,
        },
        {
          key: 'email',
          sortable: true,
          label: 'Facilitador',
        },
        {
          key: 'facilitatorReport',
          label: 'Relatório',
          formatter: (value, key, item) => {
            if (item.report) {
              return true;
            }
            return false;
          },
        },
        {
          key: 'created_at',
          sortable: true,
          label: 'Iniciado em',
          formatter: (value) => {
            if (!value) {
              return '';
            }
            const date = new Date(value);

            return [
              date.getDate().toString().length < 2
                ? `0${date.getDate()}`
                : date.getDate(),
              (date.getMonth() + 1).toString().length < 2
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1,
              date.getFullYear(),
            ].join('/');
          },
        },
        {
          key: 'updated_at',
          sortable: true,
          label: 'Última alteração',
          formatter: (value) => {
            if (!value) {
              return '';
            }
            const date = new Date(value);

            return [
              date.getDate().toString().length < 2
                ? `0${date.getDate()}`
                : date.getDate(),
              (date.getMonth() + 1).toString().length < 2
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1,
              date.getFullYear(),
            ].join('/');
          },
        },
        {
          key: 'finished_at',
          sortable: true,
          label: 'Finalizado em',
          formatter: (value) => {
            if (!value) {
              return '';
            }
            const date = new Date(value);

            return [
              date.getDate().toString().length < 2
                ? `0${date.getDate()}`
                : date.getDate(),
              (date.getMonth() + 1).toString().length < 2
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1,
              date.getFullYear(),
            ].join('/');
          },
        },
        { key: 'count', sortable: true, label: 'Nº de respostas dadas' },
        { key: 'actions', label: 'Ações', thStyle: { width: '10%' } },
      ],
      quizzes: null,
    };
  },
  computed: {
    rowsQuizzes() {
      if (this.quizzes) {
        return this.quizzes.length;
      }
      return 0;
    },
  },
  async created() {
    this.setLoading(true);
    await this.getQuizzes();
    this.setLoading(false);
  },
  methods: {
    async getQuizzes() {
      await get('quiz/', { all: true, lite: false }).then((response) => {
        this.quizzes = [];
        response.data.forEach((quiz) => {
          let newObj = {};
          newObj = {
            ...quiz.quiz,
            finished_at:
              quiz.quiz.status === 'submitted' ? quiz.quiz.updated_at : null,
            total: quiz.total,
            count: quiz.responses_count,
            company: quiz.company,
            submitted: quiz.submitted,
          };
          this.quizzes.push(newObj);
        });
      });
    },
    async toggleDetails(row) {
      this.toggling = true;
      row.toggleDetails();
      if (!row.detailsShowing) {
        await this.openReport(row.item.id);
      }
      this.toggling = false;
    },
    async openReport(id) {
      await get(`score/${id}`, {}).then((response) => {
        this.report = response.data;
      });
    },
    goToQuiz(quiz) {
      this.$store.commit('setDisabled', true);
      this.$store.commit('setQuiz', quiz);
      this.$router.push({ name: 'quiz' });
    },
    async downloadFacilitatorReport(file, report) {
      const linkSource = `data:application/${file.split('.').slice(-1)};base64,${report}`;
      const downloadLink = document.createElement('a');
      const fileName = file;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    async download(id) {
      await get(`quiz/${id}`).then((response) => {
        const linkSource = `data:application/pdf;base64,${response.data.pdf}`;
        const downloadLink = document.createElement('a');
        const fileName = 'RelatórioI4.0.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
    },
    async getResponses(id) {
      if (id) {
        const response = await get(`responses/${id}`);
        return response.data.responses;
      }
      const response = await get('responses/');
      return response.data.responses;
    },
  },
};
</script>
